/**
 * コモンクラス
 * @constructor
 */
class News {
  constructor() {

  }
  /**
   * コモン
   */
  init() {

    $.getJSON("../assets/json/news.json", function(news_list){
      for(let i in news_list){
        let list = '<div class="news__infoInner">'
              +'<div class="news__date">'
              + news_list[i].date
              + '</div>'
              + '<div class="news__lead">'
              + news_list[i].text
              + '</div>'
              + '</div>'
              + '<br>';
        $(".news__info").append(list);
      }
    });

  }

}

export default News;
