/**
 * サンプルクラス
 * @constructor
 */
class Scroll {
  constructor() {

  }
  /**
   * ヘッダー分のズレ防止
   */
  init() {

    if (window.matchMedia( "(max-width: 768px)" ).matches) {
      $(function(){
          let headerHight = 60; //SPヘッダの高さ
          $('a[href^="#"]').on('click',function(){
              let href= $(this).attr("href");
              let target = $(href == "#" || href == "" ? 'html' : href);
                  let position = target.offset().top-headerHight;
              $("html, body").animate({scrollTop:position}, 800, "swing");
                  return false;
          });
      });
      } else {
          $(function(){
              let headerHight = 100; //PCヘッダの高さ
              $('a[href^="#"]').on('click',function(){
                  let href= $(this).attr("href");
                  let target = $(href == "#" || href == "" ? 'html' : href);
                      let position = target.offset().top-headerHight;
                  $("html, body").animate({scrollTop:position}, 800, "swing");
                      return false;
              });
          });
      }
      
  }
};

export default Scroll;
