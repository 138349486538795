
class Hamburger {
  constructor() {

  }

  init() {

    $('.icon').on('click', (e) => {
      this.toggleMenu(e.currentTarget);
    });

    $('.icon').on('click', (e) => {
      this.spGnavToggle(e.currentTarget);
    });

    $('.l-header__spListItem').on('click', (e) => {
      this.closeMenu(e.currentTarget);
      this.spBgClose(e.currentTarget);
    });


    $('.icon').on('click', (e) => {
      this.bgToggle(e.currentTarget);
    });

    $('.l-header__spBg').on('click', (e) => {
      this.spBgClose(e.currentTarget);
      this.closeMenu(e.currentTarget);
    });

  }

  toggleMenu(el) {
    ($(el).hasClass('is-active')) ? this.closeSpMenu(el) : this.openSpMenu(el);
  }
  openSpMenu(el) {
    $(el).addClass('is-active');
  }
  closeSpMenu(el) {
    $(el).removeClass('is-active');
  }
  spGnavToggle() {
    ($('.l-header__spList').hasClass('is-active')) ? this.spGnavClose() : this.spGnavOpen();
  }
  spGnavOpen() {
    $('.l-header__spList').addClass('is-active');
  }
  spGnavClose() {
    $('.l-header__spList').removeClass('is-active');
  }
  closeMenu(el) {
    if ($('.icon').hasClass('is-active')) {
      $('.l-header__spList').removeClass('is-active');
      $('.icon').removeClass('is-active');
    }
  }

  // 背景
  bgToggle() {
    ($('.l-header__spBg').hasClass('is-active')) ? this.spBgClose() : this.spBgOpen();
  }
  spBgOpen() {
    $('.l-header__spBg').addClass('is-active');
  }
  spBgClose() {
    $('.l-header__spBg').removeClass('is-active');
  }
};

export default Hamburger;
